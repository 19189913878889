import React from 'react';

import 'css/components/www/LandingV3/_LandingBento.scss';

import Colors from 'common/colors/constants';
import LazyLoadedImage from 'common/LazyLoadedImage';
import nbspLastSpace from 'common/util/nbspLastSpace';
import { convertToSrcSetString } from 'common/www/LandingV3/utils';

import type { LandingImage } from 'common/www/LandingV3/types';

type Copy = {
  title: string;
  subtitle: string;
};

type Box = {
  backgroundColor: keyof typeof Colors;
  title: string;
  body: string;
  image?: LandingImage;
};

export type Props = {
  copy: Copy;
  boxes: Box[];
};

const LandingBento = ({ copy: { title, subtitle }, boxes }: Props) => {
  return (
    <section className="LandingBento">
      <h2 className="LandingBento__title">{nbspLastSpace(title)}</h2>
      <p className="LandingBento__subtitle">{subtitle}</p>
      <div className="LandingBento__bento-container">
        {boxes.map(({ title, body, backgroundColor, image }, index) => {
          return (
            <article
              className="LandingBento__bento-box"
              key={index}
              style={{ borderColor: Colors[backgroundColor] }}>
              <div
                className="LandingBento__bento-bg"
                style={{ background: Colors[backgroundColor] }}
              />
              <div className="LandingBento__bento-copy">
                <h3 className="LandingBento__bento-title">{title}</h3>
                <p className="LandingBento__bento-body">{nbspLastSpace(body)}</p>
              </div>
              {image ? (
                <LazyLoadedImage
                  {...image}
                  srcSet={convertToSrcSetString(image.srcSet)}
                  className="LandingBento__bento-image"
                  loading="lazy"
                />
              ) : null}
            </article>
          );
        })}
      </div>
    </section>
  );
};

export default LandingBento;
