import React from 'react';

import 'css/components/www/LandingV3/_LandingLogos.scss';

import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import nbspLastSpace from 'common/util/nbspLastSpace';

import type { LandingUnresponsiveImage } from 'common/www/LandingV3/types';

type Logo = {
  company: string;
  url: string;
  image: LandingUnresponsiveImage;
};

type Copy = {
  title: string;
};

export type Props = {
  copy: Copy;
  logos: Logo[];
};

const LandingLogos = ({ copy: { title }, logos }: Props) => {
  return (
    <section className="LandingLogos">
      <h2 className="LandingLogos__title">{nbspLastSpace(title)}</h2>
      <nav className="LandingLogos__nav">
        <ul className="LandingLogos__logo-container">
          {logos.map(({ company, url, image }, index) => {
            return (
              <li className={`LandingLogos__logo-item ${company}`} key={index}>
                <Link to={url} className="LandingLogos__logo-link">
                  <LazyLoadedImage
                    {...image}
                    alt={`View the ${company} case study`}
                    className={`LandingLogos__logo-image LandingLogos__logo-image--${image.height}`}
                    loading="lazy"
                  />
                </Link>
              </li>
            );
          })}
        </ul>
      </nav>
    </section>
  );
};

export default LandingLogos;
