import type { SrcSet } from 'common/www/LandingV3/types';

export const convertToSrcSetString = (sources?: SrcSet[]) => {
  if (!sources) {
    return '';
  }
  return sources
    .map(({ src, sizeMarker = '' }) => {
      return `${src} ${sizeMarker}`;
    })
    .join(', ');
};
