import React, { useContext } from 'react';

import { Play } from 'lucide-react';

import 'css/components/www/LandingV3/_LandingHeroVisual.scss';

import { TrackEventContext } from 'common/containers/EventContainer';
import { LoadVideoContext, PlayVideoContext } from 'common/containers/WistiaContainer';
import LazyLoadedImage from 'common/LazyLoadedImage';
import { convertToSrcSetString } from 'common/www/LandingV3/utils';

import type { LandingImage } from 'common/www/LandingV3/types';

const HeroImage = ({ image }: { image: LandingImage }) => {
  return (
    <LazyLoadedImage
      {...image}
      srcSet={convertToSrcSetString(image.srcSet)}
      loading="eager"
      className="LandingHeroVisual__image"
    />
  );
};

export type LandingHeroImageProps = {
  image: LandingImage;
};

export const LandingHeroImage = ({ image }: LandingHeroImageProps) => {
  return (
    <section className="LandingHeroVisual">
      <div className="LandingHeroVisual__inner-wrapper">
        <HeroImage image={image} />
      </div>
    </section>
  );
};

export type LandingHeroVideoProps = {
  copy: {
    playButton: string;
  };
  videoID: string;
  image: LandingImage;
};

export const LandingHeroVideo = ({
  copy: { playButton } = { playButton: 'See how it works' },
  videoID,
  image,
}: LandingHeroVideoProps) => {
  const loadVideo = useContext(LoadVideoContext);
  const playVideo = useContext(PlayVideoContext);
  const trackEvent = useContext(TrackEventContext);

  const onLoadVideo = () => {
    if (!videoID) {
      return;
    }
    loadVideo(videoID, (video: any) => {
      if (video.trackingEvents) {
        return;
      }
      video.trackingEvents = true;
      video.bind('play', () => {
        trackEvent('Started Landing Video');
        return video.unbind;
      });
      video.bind('secondchange', (seconds: number) => {
        if (seconds !== 60) {
          return;
        }
        trackEvent('Finished Landing Video');
        return video.unbind;
      });
    });
  };

  const onPlayVideo = () => {
    if (!videoID) {
      return;
    }
    onLoadVideo();
    playVideo(videoID);
  };

  return (
    <section className="LandingHeroVisual">
      <div className="LandingHeroVisual__inner-wrapper">
        <HeroImage image={image} />
        {videoID ? (
          <button onClick={onPlayVideo} className="LandingHeroVisual__play-button">
            <Play className="LandingHeroVisual__play-icon" size={18} />
            {playButton}
          </button>
        ) : null}
      </div>
    </section>
  );
};
