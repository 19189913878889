import React from 'react';

import 'css/components/www/LandingV3/_LandingTestimonialCard.scss';

import LazyLoadedImage from 'common/LazyLoadedImage';
import Link from 'common/Link';
import nbspLastSpace from 'common/util/nbspLastSpace';

import type { LandingUnresponsiveImage } from 'common/www/LandingV3/types';

export type Props = {
  linkUrl: string;
  logo: LandingUnresponsiveImage;
  body: string;
  person: {
    name: string;
    imageUrl: string;
    title: string;
  };
};

const LandingLandingTestimonialCard = ({ linkUrl, body, person, logo }: Props) => {
  return (
    <Link className="LandingTestimonialCard" to={linkUrl}>
      <div className="LandingTestimonialCard__copy">
        <h3 className="LandingTestimonialCard__logo">
          <LazyLoadedImage
            {...logo}
            className="LandingTestimonialCard__logo-image"
            loading="lazy"
          />
        </h3>
        <p className="LandingTestimonialCard__body">{nbspLastSpace(body)}</p>
      </div>
      <div className="LandingTestimonialCard__person-container">
        <LazyLoadedImage
          src={person.imageUrl}
          alt="" // no alt text, as the person's name/title is the context
          className="LandingTestimonialCard__person-image"
          width="52"
          height="52"
          loading="lazy"
        />
        <p className="LandingTestimonialCard__person-details">
          <span className="LandingTestimonialCard__person-name">{person.name}</span>
          <span className="LandingTestimonialCard__person-title">{person.title}</span>
        </p>
      </div>
    </Link>
  );
};

export default LandingLandingTestimonialCard;
