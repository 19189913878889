import { SVGLogos, WEBPLogos } from 'common/www/assets/CompanyLogos';

import type { Integration } from 'common/www/LandingV3/LandingIntegrations';

const Okta: Integration = {
  logo: {
    src: WEBPLogos.Okta,
    alt: 'Okta',
    width: 96,
    height: 96,
  },
  link: '/integrations/okta',
};

const Azure: Integration = {
  logo: {
    src: WEBPLogos.Azure,
    alt: 'Azure',
    width: 96,
    height: 96,
  },
  link: '/integrations/azure-active-directory',
};

const Slack: Integration = {
  logo: {
    src: SVGLogos.Slack,
    alt: 'Slack',
    width: 96,
    height: 96,
  },
  link: '/integrations/slack',
};

const ClickUp: Integration = {
  logo: {
    src: WEBPLogos.ColorfulClickupLogo,
    alt: 'Click Up',
    width: 96,
    height: 96,
  },
  link: '/integrations/clickup',
};

const Zapier: Integration = {
  logo: {
    src: SVGLogos.Zapier,
    alt: 'Zapier',
    width: 96,
    height: 96,
  },
  link: 'https://zapier.com/apps/canny/integrations',
  external: true,
};

const Jira: Integration = {
  logo: {
    src: SVGLogos.Jira,
    alt: 'Jira',
    width: 96,
    height: 96,
  },
  link: '/integrations/jira',
};

const Asana: Integration = {
  logo: {
    src: WEBPLogos.AsanaLogo,
    alt: 'Asana',
    width: 104,
    height: 96,
  },
  link: '/integrations/asana',
};

const Intercom: Integration = {
  logo: {
    src: SVGLogos.Intercom,
    alt: 'Intercom',
    width: 96,
    height: 96,
  },
  link: '/integrations/intercom',
};

const Salesforce: Integration = {
  logo: {
    src: SVGLogos.Salesforce,
    alt: 'Salesforce',
    width: 137,
    height: 96,
  },
  link: '/integrations/salesforce',
};

const Hubspot: Integration = {
  logo: {
    src: WEBPLogos.HubspotLogo,
    alt: 'Hubspot',
    width: 96,
    height: 96,
  },
  link: '/integrations/hubspot',
};

const GitHub: Integration = {
  logo: {
    src: SVGLogos.GitHub,
    alt: 'GitHub',
    width: 98,
    height: 96,
  },
  link: '/integrations/github',
};

const Zendesk: Integration = {
  logo: {
    src: SVGLogos.Zendesk,
    alt: 'Zendesk',
    width: 127,
    height: 96,
  },
  link: '/integrations/zendesk',
};

export default {
  Azure,
  Slack,
  ClickUp,
  Zapier,
  Jira,
  Asana,
  Intercom,
  Salesforce,
  Hubspot,
  GitHub,
  Okta,
  Zendesk,
};
